import {
    Auth, Amplify, Hub,
} from 'aws-amplify'
import {
    Loader, useAuthenticator, withAuthenticator,
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import React, { useEffect, useState } from 'react'
import { AmplifyUser } from '@aws-amplify/ui'
import config from './config/amplify'
import * as services from './services'
import * as components from './components'
import * as formFields from './formFields'
// eslint-disable-next-line import/named
import { ApiUser, ROLE_NO_LOGIN } from './utils/getUser'
import {
    getUser, mustHaveMFA, getRedirectRoute, NoAccessError,
    setupInternationalization, VerifyEmailError, ExpiredSessionError,
    getLanguage, NoMfaError, storeLanguage,
    sendVerificationEmail
} from './utils'
import InvalidUserAlert from './components/invalidUserAlert'

setupInternationalization()

export function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
        const { event } = payload
        if (event === 'signOut') {
            const language = getLanguage()
            localStorage.clear()
            storeLanguage(language)
        }
    })
}

Amplify.configure(config)
listenToAutoSignInEvent()

interface middlewareProps {
  cognitoUser: AmplifyUser & {preferredMFA?: string},
  user: ApiUser | null,
}

type redirectProps = Pick<middlewareProps, 'cognitoUser' | 'user'>;

export function handleRedirect({ cognitoUser, user }: redirectProps) {
    const win: Window = window
    const userSession = cognitoUser.getSignInUserSession()
    const idToken = userSession.getIdToken().getJwtToken()
    const hashParam = `#id_token=${idToken}`
    const route = getRedirectRoute(user)
    win.location.replace(`${route}${hashParam}`)
}

export async function middleware({
    cognitoUser, user
}: middlewareProps): Promise<boolean> {
    const userSession = cognitoUser.getSignInUserSession()
    if (userSession === null || user === null || !userSession.isValid()) {
        throw new ExpiredSessionError()
    }
    if (mustHaveMFA(user)) {
        if (cognitoUser.preferredMFA === 'NOMFA') {
            try {
                await Auth.setPreferredMFA(cognitoUser, 'TOTP')
            } catch (error) {
                throw new NoMfaError()
            }
        }
        if (user.email_verified === false) {
            await sendVerificationEmail(cognitoUser)
            throw new VerifyEmailError()
        }
    }
    if (user.role_id === ROLE_NO_LOGIN) {
        throw new NoAccessError()
    }
    return true
}

const loaderStyleProps = {
    style: {
        marginLeft: '42%',
        marginTop: '42%',
    },
    width: '5rem',
    height: '5rem',
}

export function App() {
    const [error, setError] = useState(null)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { user: cognitoUser, signOut }: {user: any, signOut: () => void} = useAuthenticator((context) => [context.user])
    const { route } = useAuthenticator(context => [context.route])
    useEffect(() => {
        if (route !== 'authenticated') {
            return
        }
        const guard = async () => {
            const user = await getUser(cognitoUser)
            const shouldRedirect = await middleware({user, cognitoUser})
            if(!shouldRedirect) {
                return
            }
            await handleRedirect({cognitoUser, user})
        }
        guard().catch((e) => {
            if (e instanceof VerifyEmailError) {
                setError(e)
            } else {
                console.log(e)
                setError(null)
                signOut()
            }
        })
    }, [route])
    const content = error ? <InvalidUserAlert signOut={() => {setError(null); signOut()}} /> : <Loader {...loaderStyleProps} />
    return (
        <main>
            {content}
        </main>
    )
}

export default withAuthenticator(
    App,
    {
        variation: 'default',
        loginMechanisms: ['email'],
        hideSignUp: true,
        services,
        components,
        formFields,
    },
)

