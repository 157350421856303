/* eslint-disable no-param-reassign */
import { Auth } from 'aws-amplify'
import { getUser, mustHaveMFA, ROLE_NO_LOGIN } from '../utils'
import { NoAccessError } from 'src/utils'

// Auth.signIn returns any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function setMFARequirements(cognitoUser: any) {
    cognitoUser.challengeName = 'MFA_SETUP'
    cognitoUser.challengeParam = {
        MFAS_CAN_SETUP: '["SOFTWARE_TOKEN_MFA"]',
        USER_ID_FOR_SRP: cognitoUser.attributes.sub,
    }
    return cognitoUser
}

export async function handleSignIn({ username, password }: {username: string, password: string}) {
    let cognitoUser = await Auth.signIn(username, password)
    if (cognitoUser?.signInUserSession === null) {
        return cognitoUser
    }
    const apiUser = await getUser(cognitoUser)
    if (apiUser.role_id === ROLE_NO_LOGIN) {
        Auth.signOut()
        throw new NoAccessError()
    }
    if (process.env.REACT_APP_DISABLE_MFA !== 'true' && cognitoUser.preferredMFA === 'NOMFA' && mustHaveMFA(apiUser)) {
        cognitoUser = await setMFARequirements(cognitoUser)
    }
    return cognitoUser
}

export default handleSignIn
