import { I18n } from 'aws-amplify'
import LogOutError from './logOutError'

export class NoAccessError extends LogOutError {
    constructor() {
        super(I18n.get('Your account has been disabled.'
        +' If you think this is an error, please contact support@webgains.com.'))
    }
}

export default NoAccessError
